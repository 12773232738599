import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
    button: {
      textTransform: "none",
    },
    disabledAccordion: {
      backgroundColor: "transparent",
    },
  },
});

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};
